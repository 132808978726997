import { createSlice } from '@reduxjs/toolkit';
import { IStateConfigurations } from 'assets/ts/types';

const initialState: IStateConfigurations = {
    filterByCurrentApp: false,
    filterByCurrentUser: false,
    filterOnlyDraft: false,
    selectedProjectId: null,
    sortByColumn: null,
};

const configurationsReducer = createSlice({
    name: 'configurations',
    initialState,
    reducers: {
        setFilterByCurrentApp(state, action) {
            return { ...state, filterByCurrentApp: action.payload };
        },
        setFilterByCurrentUser(state, action) {
            return { ...state, filterByCurrentUser: action.payload };
        },
        setFilterOnlyDraft(state, action) {
            return { ...state, filterOnlyDraft: action.payload };
        },
        setSelectedProjectId(state, action) {
            return { ...state, selectedProjectId: action.payload };
        },
        setSortByColumn(state, action) {
            return { ...state, sortByColumn: action.payload };
        },
    },
});

export const { setFilterByCurrentApp, setFilterByCurrentUser, setFilterOnlyDraft, setSelectedProjectId, setSortByColumn } =
    configurationsReducer.actions;
export default configurationsReducer.reducer;
