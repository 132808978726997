import convertAppsList from 'assets/ts/helpers/convert-data/apps-list';
import convertCategoriesData from 'assets/ts/helpers/convert-data/categories-data';
import convertCategoryProductForm from 'assets/ts/helpers/convert-data/category-product-form';
import convertCategoryProductsData from 'assets/ts/helpers/convert-data/category-products';
import convertCategorySettings from 'assets/ts/helpers/convert-data/category-settings';
import convertCoeffs from 'assets/ts/helpers/convert-data/coeffs';
import convertCompanyData from 'assets/ts/helpers/convert-data/company';
import convertConfigurationForm from 'assets/ts/helpers/convert-data/configuration-form';
import convertConfigurationHistory from 'assets/ts/helpers/convert-data/configuration-history';
import convertConfigurationPriceData from 'assets/ts/helpers/convert-data/configuration-price';
import convertConfigurations from 'assets/ts/helpers/convert-data/configurations';
import convertEducation from 'assets/ts/helpers/convert-data/education';
import convertFeedbackAdmin from 'assets/ts/helpers/convert-data/feedback-admin';
import convertNews from 'assets/ts/helpers/convert-data/news';
import convertNewsAdmin from 'assets/ts/helpers/convert-data/news-admin';
import convertNotifications from 'assets/ts/helpers/convert-data/notifications';
import convertPricesMonitoring from 'assets/ts/helpers/convert-data/prices-monitoring';
import convertProductAssociations from 'assets/ts/helpers/convert-data/product-associations';
import convertProductsData from 'assets/ts/helpers/convert-data/products';
import convertProductsArticlesPim from 'assets/ts/helpers/convert-data/products-articles-pim';
import convertProjects from 'assets/ts/helpers/convert-data/projects';
import convertRsUploadData from 'assets/ts/helpers/convert-data/rs-upload';
import convertStorages from 'assets/ts/helpers/convert-data/storages';
import convertUserRoles from 'assets/ts/helpers/convert-data/user-roles';
import convertUserSettings from 'assets/ts/helpers/convert-data/user-settings';
import convertUsersData from 'assets/ts/helpers/convert-data/users';
import convertUsersDifferentLoginData from 'assets/ts/helpers/convert-data/users-different-login';
import convertUsersRsData from 'assets/ts/helpers/convert-data/users-rs';

const convertData = (requestType: string, data: any, addData?: any) => {
    switch (requestType) {
        case 'apps-list':
            return convertAppsList(data);
        case 'users':
            return convertUsersData(data);
        case 'auth-different-users':
            return convertUsersDifferentLoginData(data);
        case 'users-rs':
            return convertUsersRsData(data);
        case 'categories-data':
            return convertCategoriesData(data);
        case 'category-settings':
            return convertCategorySettings(data);
        case 'category-products':
            return convertCategoryProductsData(data);
        case 'category-product-form':
            return convertCategoryProductForm(data);
        case 'product-associations':
            return convertProductAssociations(data);
        case 'products':
            return convertProductsData(data, addData);
        case 'products-articles-pim':
            return convertProductsArticlesPim(data);
        case 'configurations':
            return convertConfigurations(data);
        case 'configuration-price':
            return convertConfigurationPriceData(data);
        case 'configurations-history':
            return convertConfigurationHistory(data);
        case 'rs-upload':
            return convertRsUploadData(data);
        case 'configuration-form':
            return convertConfigurationForm(data);
        case 'coeffs':
            return convertCoeffs(data);
        case 'projects':
            return convertProjects(data);
        case 'user-settings':
            return convertUserSettings(data);
        case 'user-roles':
            return convertUserRoles(data);
        case 'storages':
            return convertStorages(data);
        case 'prices-monitoring':
            return convertPricesMonitoring(data);
        case 'news':
            return convertNews(data);
        case 'news-admin':
            return convertNewsAdmin(data);
        case 'notifications':
            return convertNotifications(data);
        case 'company':
            return convertCompanyData(data);
        case 'feedback-admin':
            return convertFeedbackAdmin(data);
        case 'education':
            return convertEducation(data);
        default:
            return data;
    }
};

export default convertData;
