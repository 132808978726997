import {
    IConfigurationForm,
    IConfigurationFormCategory,
    IConfigurationFormField,
    IConfigurationFormProduct,
    IConfigurationFormProductPimInfo,
    IConfigurationFormSearchAttribute,
    IConfigurationFormSearchForm,
    IConfigurationFormSlot,
    IConfigurationFormSlotFilter,
    IDataConfigurationForm,
    IDataConfigurationFormCategory,
    IDataConfigurationFormField,
    IDataConfigurationFormProduct,
    IDataConfigurationFormProductPimInfo,
    IDataConfigurationFormSearchAttribute,
    IDataConfigurationFormSearchForm,
    IDataConfigurationFormSlot,
    IDataConfigurationFormSlotFilter,
    IDataConfigurationFormSlotGrouped,
    IDataFormField,
    IFormField,
} from 'assets/ts/types';
import { convertValidation } from 'assets/ts/helpers/forms';

const convertSlotFilters = (filters: IDataConfigurationFormSlotFilter[] | null) =>
    filters
        ? filters.map(
              ({ validation, description_field, options, ...rest }) =>
                  ({
                      ...rest,
                      tip: description_field,
                      validation: convertValidation(validation),
                      options: validation.type === 'bool_list' ? ['Да', 'Нет'] : options,
                  } as IConfigurationFormSlotFilter)
          )
        : null;

const convertProduct = (product: IDataConfigurationFormProduct) => {
    const { pricelist_id, count, count_in_stock, is_recommended, only_for_presale, show_pim_params, name_html, ...rest } = product;
    return {
        ...rest,
        nameHtml: name_html,
        articul: pricelist_id,
        amountInStock: count_in_stock ?? 0,
        amountRequired: count,
        recommended: is_recommended,
        onlyForPresale: only_for_presale,
        showPimParams: show_pim_params,
    } as IConfigurationFormProduct;
};

const convertSlots = (slots: IDataConfigurationFormSlot[]) =>
    slots.map(slot => {
        const { name, selected_item, additional_items, ...rest } = slot;
        const [type, index] = name.split(':');

        return {
            name,
            type,
            index: Number(index),
            selectedItem: selected_item ? convertProduct(selected_item) : null,
            additionalItems: additional_items ? additional_items.map(ai => convertProduct(ai)) : null,
            amount: 1,
            ...rest,
        } as IConfigurationFormSlot;
    });

const convertSlotsGrouped = (slots: IDataConfigurationFormSlotGrouped[] | null, categoryName: string) =>
    slots
        ? slots.map((slot, i) => {
              const {
                  selected_item,
                  additional_items,
                  available_items,
                  count_available,
                  count_installed,
                  show_count,
                  show_count_additional_items,
                  hide_price,
                  hide_price_additional_items,
                  is_disabled,
                  ...rest
              } = slot;
              return {
                  name: `${categoryName}:${i}`,
                  type: categoryName,
                  index: i,
                  selectedItem: selected_item ? convertProduct(selected_item) : null,
                  additionalItems: additional_items ? additional_items.map(ai => convertProduct(ai)) : null,
                  availableItems: available_items ? available_items.map(i => convertProduct(i)) : null,
                  availableAmount: count_available,
                  amount: count_installed,
                  showAmountInStock: show_count,
                  showAmountInStockAdditionalItems: show_count_additional_items,
                  hidePrice: hide_price,
                  hidePriceAdditionalItems: hide_price_additional_items,
                  disabled: is_disabled,
                  ...rest,
              } as IConfigurationFormSlot;
          })
        : null;

const convertCategories = (categories: IDataConfigurationFormCategory[]) =>
    categories.map(cat => {
        const {
            name,
            display_name,
            independent_slots,
            slots,
            slots_group,
            available_items,
            available_amount,
            show_more_button,
            show_select_amount,
            show_count,
            show_count_additional_items,
            hide_price,
            hide_price_additional_items,
            is_disabled,
            search_available,
            search_regex,
            virtual_item_id,
            clear_categories_if_empty,
            is_use_new_slots_group,
            form_filters,
            ...rest
        } = cat;

        return {
            name,
            title: display_name,
            independentSlots: independent_slots,
            slots: convertSlots(slots),
            slotsGrouped: convertSlotsGrouped(slots_group, name),
            availableItems: available_items ? available_items.map(i => convertProduct(i)) : null,
            availableAmount: available_amount,
            showSelectAmount: show_select_amount,
            showButtonMore: show_more_button,
            showAmountInStock: show_count,
            showAmountInStockAdditionalItems: show_count_additional_items,
            hidePrice: hide_price,
            hidePriceAdditionalItems: hide_price_additional_items,
            disabled: is_disabled,
            searchAvailable: search_available,
            searchRegex: search_regex,
            virtualItemId: virtual_item_id,
            clearCategoriesIfCurrentCategoryEmpty: clear_categories_if_empty,
            useSlotsGrouped: is_use_new_slots_group,
            slotFilters: convertSlotFilters(form_filters),
            ...rest,
        } as IConfigurationFormCategory;
    });

const convertFields = (fields: IDataConfigurationFormField[] = []) =>
    fields.map(field => {
        const { validation, button, is_disabled, ...rest } = field;
        return {
            ...rest,
            disabled: is_disabled ?? false,
            validation: convertValidation(validation),
            button: button ? { actionName: button.action, actionTitle: button.button_text, allowEmpty: button.allow_empty } : null,
        } as IConfigurationFormField;
    });

const convertConfigurationForm = ({ categories, count, app_id, fields, parent_id, parent_naming, ...rest }: IDataConfigurationForm) =>
    ({
        ...rest,
        appId: app_id,
        amount: count,
        categories: convertCategories(categories),
        fields: convertFields(fields),
        parentId: parent_id,
        parentNaming: parent_naming,
    } as IConfigurationForm);

const convertConfigurationFormSearchAttributes = (searchAttributes: IDataConfigurationFormSearchAttribute[]) =>
    searchAttributes.map(
        ({ display_name, is_required, ...rest }) => ({ title: display_name, required: is_required, ...rest } as IConfigurationFormSearchAttribute)
    );

const convertConfigurationFormSearchForm = ({ available_action, attributes, items, virtual_item_price, ...rest }: IDataConfigurationFormSearchForm) =>
    ({
        actionAvailable: available_action,
        attributes: convertConfigurationFormSearchAttributes(attributes),
        items: (items ?? []).map(i => convertProduct(i)),
        virtualItemPrice: virtual_item_price,
    } as IConfigurationFormSearchForm);

const convertConfigurationFormProductPimInfo = ({ category_title, photo_url, values, ...rest }: IDataConfigurationFormProductPimInfo) =>
    ({
        ...rest,
        categoryTitle: category_title,
        photoUrl: photo_url || null,
        attributes: { ...values },
    } as IConfigurationFormProductPimInfo);

export default convertConfigurationForm;
export {
    convertCategories,
    convertSlots,
    convertSlotsGrouped,
    convertProduct,
    convertFields,
    convertConfigurationFormSearchForm,
    convertConfigurationFormSearchAttributes,
    convertConfigurationFormProductPimInfo,
    convertSlotFilters,
};
