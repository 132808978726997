import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    id: null,
    token: '',
    login: '',
    rsId: '',
    roleId: '',
    roleCode: '',
    roleName: '',
    firstName: '',
    lastName: '',
    patronymic: '',
    partnerName: '',
    position: '',
    inn: '',
    phone: '',
    flags: [],
};

const userReducer = createSlice({
    name: 'user',
    initialState,
    reducers: {
        logIn(state, action) {
            return { ...state, ...action.payload };
        },
        logOut(state) {
            return { ...state, ...initialState };
        },
        update(state, action) {
            return { ...state, ...action.payload };
        },
    },
});

export { initialState };
export const { logIn, logOut, update } = userReducer.actions;
export default userReducer.reducer;
