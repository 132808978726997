import React, { useCallback, useContext } from 'react';
import { OptionProps } from 'react-select';
import styled from '@emotion/styled';
import cx from 'classnames';
import ModalContext from 'store/context/ModalContext';
import { ONLY_FOR_PRESALE_TAG_TEXT } from 'modules/configurator-form/assets';
import Button from 'components/button';
import { IConfigurationFormProduct, ISelectOption } from 'assets/ts/types';

const COLOR_FOCUSED = 'rgba(217, 217, 217, 0.26)';
const COLOR_SELECTED = 'rgba(55, 105, 151, 0.33)';
const COLOR_ACCENT = '#1890ff';
const COLOR_DANGER = '#d91745';
const COLOR_ALERT_INFO_TEXT = '#0c5460';
const COLOR_ALERT_INFO_BG = '#d1ecf1';
const COLOR_ALERT_INFO_BORDER = '#bee5eb';

const Wrapper = styled.div<{ isFocused: boolean; isSelected: boolean }>`
    padding: 9px 11px 12px;
    display: flex;
    gap: 21px;
    background: ${props => {
        let bg = 'transparent';
        if (props.isFocused) bg = COLOR_FOCUSED;
        if (props.isSelected) bg = COLOR_SELECTED;
        return bg;
    }};

    cursor: ${props => (!props.isSelected && props.isFocused ? 'pointer' : 'auto')};
`;

const Option = (props: OptionProps<IConfigurationFormProduct & ISelectOption>) => {
    const { setData: setModal } = useContext(ModalContext);

    const { data: option, isSelected, isFocused, innerRef, innerProps, label } = props;
    const {
        name: optionName,
        nameHtml: optionNameHtml,
        price: optionPrice,
        noPrice,
        amountInStock,
        showAmountInStock,
        onlyForPresale,
        articul,
        showPimParams,
        getPimInfoModalOptions,
    } = option;
    const price = optionPrice ? optionPrice.toLocaleString() : 'Нет цены';

    const getPimInfo = useCallback(() => {
        if (!getPimInfoModalOptions) return;
        setModal(getPimInfoModalOptions(option));
    }, [option]);

    return (
        <Wrapper isSelected={isSelected} isFocused={isFocused} ref={innerRef} {...innerProps}>
            <div className="select-label">
                {optionNameHtml && !optionNameHtml.includes('<script') ? (
                    <span dangerouslySetInnerHTML={{ __html: optionNameHtml }} />
                ) : (
                    optionName || label
                )}
                {articul && showPimParams ? (
                    <Button
                        styleType="icon-only"
                        className="select-button _pim-info"
                        onClick={e => {
                            e.stopPropagation();
                            getPimInfo();
                        }}
                        tip="Подробнее о комплектующей"
                    >
                        <div className="icon" />
                    </Button>
                ) : (
                    ''
                )}

                {showAmountInStock || onlyForPresale ? (
                    <div className="select-tags">
                        {showAmountInStock && (
                            <div className={`select-tag alert alert-${amountInStock ? 'success' : 'danger'} _amount-in-stock`}>
                                {amountInStock ? `на складе ${amountInStock} шт.` : 'нет на складе'}
                            </div>
                        )}
                        {onlyForPresale && <div className="select-tag alert alert-info _presale">{ONLY_FOR_PRESALE_TAG_TEXT}</div>}
                    </div>
                ) : (
                    ''
                )}
            </div>

            {!noPrice && (
                <div className="select-price">
                    <div className="select-price__dash">&mdash;</div>
                    <div className={cx(['select-price__price', { _error: !optionPrice }])}>
                        {price} {!!optionPrice && ' $'}
                    </div>
                </div>
            )}
        </Wrapper>
    );
};

export default Option;
