import React, { useCallback, useContext } from 'react';
import { SingleValueProps, components } from 'react-select';
import styled from '@emotion/styled';
import cx from 'classnames';
import ModalContext from 'store/context/ModalContext';
import Button from 'components/button';
import { IConfigurationFormProduct, ISelectOption } from 'assets/ts/types';
import copyTextToClipboard from 'assets/ts/helpers/copy-to-clipboard';

const Wrapper = styled.div<{}>``;

const SingleValue = ({ children, ...props }: SingleValueProps<IConfigurationFormProduct & ISelectOption>) => {
    const { setData: setModal } = useContext(ModalContext);

    const { data: option } = props;
    const { name: optionName, nameHtml: optionNameHtml, price: optionPrice, noPrice, articul, showPimParams, getPimInfoModalOptions } = option;
    const price = optionPrice ? optionPrice.toLocaleString() : 'Нет цены';

    const getPimInfo = useCallback(() => {
        if (!getPimInfoModalOptions) return;
        setModal(getPimInfoModalOptions(option));
    }, [option]);

    return (
        <components.SingleValue {...props}>
            <Wrapper className="select__option-content">
                <div className={cx(['select__option-content-item select__option-name', { '_no-price': noPrice }])}>
                    {optionNameHtml && !optionNameHtml.includes('<script') ? (
                        <span dangerouslySetInnerHTML={{ __html: optionNameHtml }} />
                    ) : (
                        <span>{optionName}</span>
                    )}
                </div>
                {articul && showPimParams ? (
                    <Button
                        styleType="icon-only"
                        className="select__option-content-item select__option-btn _pim-info"
                        onMouseDown={e => {
                            e.stopPropagation();
                            getPimInfo();
                        }}
                        tip="Подробнее о комплектующей"
                    >
                        <div className="icon" />
                    </Button>
                ) : (
                    ''
                )}
                <Button
                    styleType="icon-only"
                    className="select__option-content-item select__option-btn _copy"
                    tip="Копировать наименование в буфер обмена"
                    onMouseDown={e => {
                        e.stopPropagation();
                        copyTextToClipboard({ text: optionName });
                    }}
                >
                    <div className="icon" />
                </Button>
                {!noPrice && (
                    <div className="select__option-content-item select__option-price-wrapper">
                        &mdash;
                        <span className={cx(['select__option-price', { _error: !optionPrice }])}>
                            {price} {!!optionPrice && ' $'}
                        </span>
                    </div>
                )}
            </Wrapper>
        </components.SingleValue>
    );
};

export default SingleValue;
