import React, { RefObject, Suspense, lazy, useContext, useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import ModalContext from 'store/context/ModalContext';
import Button from 'components/button';
import lazyRetry from 'assets/ts/helpers/lazy-retry';
import useWindowSize from 'assets/ts/helpers/use-window-size';
import 'modules/modal/index.scss';

const Modal = () => {
    const { data, closeModal } = useContext(ModalContext);
    const { title, moduleName, props, className } = data;

    const [onTop, setOnTop] = useState<boolean>(false);
    const component = useMemo(() => setComponent(moduleName, props), [moduleName, props]);
    const [winWidth, winHeight] = useWindowSize();
    const modalContainerRef = useRef<HTMLDivElement>(null);

    const checkModalHeight = (modalContainerRef: RefObject<HTMLDivElement>) => {
        const { current: modalContainer } = modalContainerRef;

        if (modalContainer) {
            const modalHeight = modalContainer.clientHeight + 60;
            return modalHeight > window.innerHeight;
        }

        return false;
    };

    const onCloseModal = () => {
        props.onCloseModal && props.onCloseModal();
        closeModal();
    };

    useEffect(() => {
        if (moduleName && modalContainerRef?.current) {
            document.body.style.overflow = 'hidden';
            new ResizeObserver(() => {
                setOnTop(checkModalHeight(modalContainerRef));
            }).observe(modalContainerRef.current as Element);
        } else {
            document.body.style.overflow = '';
        }
    }, [modalContainerRef, moduleName]);

    useEffect(() => {
        setOnTop(checkModalHeight(modalContainerRef));
    }, [winWidth, winHeight]);

    return moduleName ? (
        <div className={`modal${className ? ` ${className}` : ''}`}>
            <div className={cx(['modal-container', { _top: onTop }])} onClick={e => e.stopPropagation()} ref={modalContainerRef}>
                <div className="modal-head">
                    <div className="modal-title">{title}</div>
                    <Button styleType="icon" className="modal-close" onClick={onCloseModal}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="#2d2d2d"
                            className="bi bi-x-square-fill"
                            viewBox="0 0 16 16"
                        >
                            <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708z" />
                        </svg>
                    </Button>
                </div>
                <div className="modal-body">
                    <Suspense fallback="Загрузка...">{component}</Suspense>
                </div>
            </div>
        </div>
    ) : (
        <></>
    );
};

function setComponent(name: string, props: any) {
    switch (name) {
        case 'UserForm':
            return <UserForm {...props} />;
        case 'ConfigurationsListItemDetail':
            return <ConfigurationsListItemDetail {...props} />;
        case 'ConfigurationsListModal':
            return <ConfigurationsListModal {...props} />;
        case 'ConfiguratorFormModal':
            return <ConfiguratorFormModal {...props} />;
        case 'ProductForm':
            return <ProductForm {...props} />;
        case 'ProductAssociationsForm':
            return <ProductAssociationsForm {...props} />;
        case 'ProductsArticlesPimForm':
            return <ProductsArticlesPimForm {...props} />;
        case 'ProductSaveModal':
            return <ProductSaveModal {...props} />;
        case 'UploadExcelModal':
            return <UploadExcelModal {...props} />;
        case 'CategorySettings':
            return <CategorySettings {...props} />;
        case 'ProjectSaveModal':
            return <ProjectSaveModal {...props} />;
        case 'ProjectForm':
            return <ProjectForm {...props} />;
        case 'ConfiguratorFormSlotSearch':
            return <ConfiguratorFormSlotSearch {...props} />;
        case 'ConfiguratorFormSlotFilters':
            return <ConfiguratorFormSlotFilters {...props} />;
        case 'ConfiguratorFormProductPimInfo':
            return <ConfiguratorFormProductPimInfo {...props} />;
        case 'NewsAdminItemFormModal':
            return <NewsAdminItemFormModal {...props} />;
        case 'NewsDetailModal':
            return <NewsDetailModal {...props} />;
        case 'FeedbackForm':
            return <FeedbackForm {...props} />;
        case 'EducationAdminItemFormModal':
            return <EducationAdminItemForm {...props} />;
        case 'MessageModal':
            return <MessageModal {...props} />;
        case 'ConfirmAction':
        default:
            return <ConfirmAction {...props} />;
    }
}

const UserForm = lazy(() => lazyRetry(() => import(/* webpackChunkName: "modal-user-form" */ 'modules/user-form'), 'modal-user-form'));
const ConfigurationsListItemDetail = lazy(() =>
    lazyRetry(
        () => import(/* webpackChunkName: "modal-configurations-list-item-detail" */ 'modules/configurations-list/ConfigurationsListItemDetail'),
        'modal-configurations-list-item-detail'
    )
);
const ConfigurationsListModal = lazy(() =>
    lazyRetry(
        () => import(/* webpackChunkName: "modal-configurations-list" */ 'modules/configurations-list/ConfigurationsListModal'),
        'modal-configurations-list'
    )
);
const ConfiguratorFormModal = lazy(() =>
    lazyRetry(
        () => import(/* webpackChunkName: "modal-configurator-form" */ 'modules/configurator-form/ConfiguratorFormModal'),
        'modal-configurator-form'
    )
);
const ProductForm = lazy(() =>
    lazyRetry(() => import(/* webpackChunkName: "modal-product-form" */ 'modules/products/ProductForm'), 'modal-product-form')
);
const ProductAssociationsForm = lazy(() =>
    lazyRetry(
        () => import(/* webpackChunkName: "modal-product-associations-form" */ 'modules/products/ProductAssociationsForm'),
        'modal-product-associations-form'
    )
);
const ProductsArticlesPimForm = lazy(() =>
    lazyRetry(
        () => import(/* webpackChunkName: "modal-products-articles-pim-form" */ '../products/ProductsArticlesPimForm'),
        'modal-products-articles-pim-form'
    )
);
const ProductSaveModal = lazy(() =>
    lazyRetry(() => import(/* webpackChunkName: "modal-product-save" */ 'modules/products/ProductSaveModal'), 'modal-product-save')
);
const CategorySettings = lazy(() =>
    lazyRetry(() => import(/* webpackChunkName: "modal-category-settings" */ 'modules/categories/CategorySettings'), 'modal-category-settings')
);
const ProjectSaveModal = lazy(() =>
    lazyRetry(() => import(/* webpackChunkName: "modal-project-save" */ 'modules/projects/ProjectSaveModal'), 'modal-project-save')
);
const ProjectForm = lazy(() =>
    lazyRetry(() => import(/* webpackChunkName: "modal-project-form" */ 'modules/projects/ProjectForm'), 'modal-project-form')
);
const ConfiguratorFormSlotSearch = lazy(() =>
    lazyRetry(
        () => import(/* webpackChunkName: "modal-configurator-form-slot-search" */ 'modules/configurator-form/ConfiguratorFormSlotSearch'),
        'modal-configurator-form-slot-search'
    )
);
const ConfiguratorFormSlotFilters = lazy(() =>
    lazyRetry(
        () => import(/* webpackChunkName: "modal-configurator-form-slot-filters" */ '../configurator-form/ConfiguratorFormSlotFilters'),
        'modal-configurator-form-slot-filters'
    )
);
const ConfiguratorFormProductPimInfo = lazy(() =>
    lazyRetry(
        () => import(/* webpackChunkName: "modal-configurator-form-product-pim-info" */ 'modules/configurator-form/ConfiguratorFormProductPimInfo'),
        'modal-configurator-form-product-pim-info'
    )
);
const NewsAdminItemFormModal = lazy(() =>
    lazyRetry(() => import(/* webpackChunkName: "modal-news-admin-item" */ 'modules/admin/news/NewsAdminItemFormModal'), 'modal-news-admin-item')
);
const NewsDetailModal = lazy(() =>
    lazyRetry(() => import(/* webpackChunkName: "modal-news-detail" */ 'modules/news/NewsDetailModal'), 'modal-news-detail')
);
const FeedbackForm = lazy(() =>
    lazyRetry(() => import(/* webpackChunkName: "modal-feedback-form" */ 'modules/feedback-form'), 'modal-feedback-form')
);
const EducationAdminItemForm = lazy(() =>
    lazyRetry(
        () => import(/* webpackChunkName: "modal-education-admin-item-form" */ 'modules/admin/education/EducationAdminItemFormModal'),
        'modal-education-admin-item-form'
    )
);
const MessageModal = lazy(() => lazyRetry(() => import(/* webpackChunkName: "modal-message" */ 'modules/modal/MessageModal'), 'modal-message'));
const ConfirmAction = lazy(() =>
    lazyRetry(() => import(/* webpackChunkName: "modal-confirm-action" */ 'modules/modal/ConfirmAction'), 'modal-confirm-action')
);
const UploadExcelModal = lazy(() =>
    lazyRetry(() => import(/* webpackChunkName: "modal-excel-upload" */ 'modules/modal/UploadExcelModal'), 'modal-excel-upload')
);

export default Modal;
